@import url('https://fonts.googleapis.com/css2?family=Figtree:ital,wght@0,400;0,500;0,600;0,700;1,400;1,500;1,600;1,700&family=Montserrat:ital,wght@0,400;0,500;0,600;0,700;1,400;1,500;1,600;1,700&display=swap');

html {
  /* 1rem = 16px */
  font-size: 16px;
}

* {
  margin: 0;
  padding: 0;
  /* don't add border and padding to calculate element width */
  box-sizing: border-box;
}

body {
  margin: 0;
  font-family: 'KeplerStdRegular', -apple-system, BlinkMacSystemFont,
    'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans',
    'Droid Sans', 'Helvetica Neue';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-transition: 'color 9999s ease-out, background-color 9999s ease-out';
  -webkit-transition-delay: 9999s;
}

.break {
  word-wrap: break-word !important;
  white-space: normal !important;
}

.cell-center {
  display: flex;
  justify-content: center !important;
}

.date-picker {
  width: 200px !important;
}

.date-picker input {
  width: 100%;
  height: 100%;
  padding: 16px 20px;
  border: 1px solid #e9eaef;
  font-family: 'KeplerStdRegular';
  font-weight: 400;
  font-size: 15px;
  line-height: 20px;
}

.date-picker input:focus {
  outline: none;
}

.date-picker input::placeholder {
  font-family: 'KeplerStdRegular';
  font-weight: 400;
  font-size: 15px;
  line-height: 20px;
  color: #a2acac;
}

.react-datepicker-popper {
  z-index: 1000 !important;
}
